<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.svg')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Welcome to CYY Chat
        </h1>

        <p class="subheading font-weight-regular">
          <br>这里可以找到我
          <a
            href="https://cyy.im"
            target="_blank"
          >CYY.IM</a>
        </p>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-3">
          目前这是个即时通讯项目
        </h2>

      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-3">
          实现功能列表
        </h2>

        <v-row justify="center">
          <p>聊天列表</p>
        </v-row>
           <v-row justify="center">
          <p>添加好友</p>
        </v-row>
           <v-row justify="center">
          <p>添加聊天室</p>
        </v-row>
           <v-row justify="center">
          <p>语音消息</p>
        </v-row>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-3">
          请期待。。。
        </h2>

        <v-row justify="center">
          <!-- <a
            v-for="(eco, i) in ecosystem"
            :key="i"
            :href="eco.href"
            class="subheading mx-3"
            target="_blank"
          >
            {{ eco.text }}
          </a> -->
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({
   

   
    }),
  }
</script>
